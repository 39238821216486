import React, {FC, useContext, useEffect, useState} from "react"

// Components
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core"
import {Button as MantineButton, Loader} from "@mantine/core"
import {TranslationContext} from "../translationcontext"
import {UserContext} from "../usercontext"
import DatePicker from "react-datepicker"
import {useMediaQuery} from "react-responsive"

// API
import {NewReservation} from "../api"

// Lib
import {getDaysBetweenDates} from "../helpers"
import {addDays} from "date-fns"

// CSS
import "react-datepicker/dist/react-datepicker.css"

// Types
import {NewBookingModalProps} from "./interfaces"


const NewBookingModal: FC<NewBookingModalProps> = (
    {
        selectedObject, selectedDate, nextArrivalDate,
        onClose, guestRoomId, show,
        onReservationFailed
    }) => {
    const [loading, setLoading] = useState(false)
    const translations = useContext(TranslationContext)
    const userContext = useContext(UserContext)

    const [selectedFromDate, setSelectedFromDate] = useState<string | undefined>()
    const [nrOfDays, setNrOfDays] = useState<number>(0)
    const isTabletOrMobile = useMediaQuery({query: "(max-width: 500px)"})
    const [guestIsOwner, setGuestIsOwner] = useState(false)

    const [endDate, setEndDate] = useState(selectedDate)
    const months = [
        translations.availability?.january,
        translations.availability?.february,
        translations.availability?.march,
        translations.availability?.april,
        translations.availability?.may,
        translations.availability?.june,
        translations.availability?.july,
        translations.availability?.august,
        translations.availability?.september,
        translations.availability?.october,
        translations.availability?.november,
        translations.availability?.december
    ]
    const days = [
        translations.availability?.sundayThreeLetters,
        translations.availability?.mondayThreeLetters,
        translations.availability?.tuesdayThreeLetters,
        translations.availability?.wednesdayThreeLetters,
        translations.availability?.thursdayThreeLetters,
        translations.availability?.fridayThreeLetters,
        translations.availability?.saturdayThreeLetters
    ]
    const calendarLocale: Locale = {
        localize: {
            month: (n: any) => months[n],
            day: (n: any) => days[n],
            dayPeriod: () => " ",
            era: () => " ",
            ordinalNumber: () => " ",
            quarter: () => " "
        },
        formatLong: {
            date: () => " ",
            dateTime: () => " ",
            time: () => " "
        },
        options: {
            weekStartsOn: 1
        }
    }

    const SaveBooking = async () => {
        let form: any = document.getElementById("newbookingform")
        if (!form.reportValidity()) {
            return
        }
        setLoading(true)
        let from = form.fromdate.value
        let to = form.todate.value
        let numberofguests = form.nrofguests.value
        let guestFirstname: string = form.firstname.value !== "" ? form.firstname.value : userContext.user?.firstName
        let guestLastname: string = form.lastname.value !== "" ? form.lastname.value : userContext.user?.lastName
        let guestIsOwner: boolean = form.guestisowner.checked
        let result = await NewReservation(from, to, guestFirstname, guestLastname, guestIsOwner, guestRoomId, numberofguests)
        if (result === true) {
            onClose(true)
        } else {
            onReservationFailed()
        }
        setLoading(false)
    }

    useEffect(() => {
        if (show) {
            setSelectedFromDate(selectedDate?.toLocaleDateString("sv-SE"))
            setGuestIsOwner(false)
            setLoading(false)
            setNrOfDays(0)
            setEndDate(addDays(selectedDate!, 1))
        }
    }, [show, selectedDate])


    useEffect(() => {
        if (selectedFromDate !== undefined && endDate !== undefined) {
            let nrOfDays = getDaysBetweenDates(new Date(selectedFromDate), new Date(endDate)).length
            setNrOfDays(nrOfDays)
        }
    }, [endDate, selectedFromDate])

    let nextDay = new Date()
    if (selectedDate) {
        nextDay = new Date(selectedDate!.toLocaleDateString("sv-SE"))
        nextDay.setDate(selectedDate.getDate() + 1)
    }

    const updateGuestIsOwner = (guestIsOwner: boolean) => {
        let firstNameField: any = document.getElementById("guestfirstname")
        if (firstNameField !== undefined) {
            firstNameField.value = ""
        }

        let lastNameField: any = document.getElementById("guestlastname")
        if (lastNameField !== undefined) {
            lastNameField.value = ""
        }

        setGuestIsOwner(guestIsOwner)
    }

    const renderNumberOfGuestOptions = () => {
        let items = []
        for (let i = selectedObject!.minOccupancy ?? 0; i <= selectedObject!.maxOccupancy!; i++) {
            items.push(<option key={i} value={i} label={i!.toString()}/>)
        }
        return items
    }

    return (
        <Dialog open={show}
                fullScreen={isTabletOrMobile}
                disableEscapeKeyDown={true}
                onClose={() => onClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{translations.availability?.newBooking}</DialogTitle>
            <DialogContent style={{paddingBottom: "20px"}}>
                <DialogContentText id="alert-dialog-description">
                    {translations.availability?.newReservationDescription}
                </DialogContentText>
                <form id="newbookingform">
                    <br/>
                    <p>{translations.availability?.from}:</p>
                    {selectedFromDate && (
                        <DatePicker
                            disabled
                            required
                            selected={new Date(selectedFromDate!)}
                            name="fromdate"
                            onChange={(x) => setSelectedFromDate(x?.toISOString)}
                            className="to-date-picker"
                            locale={calendarLocale}
                            dateFormat="yyyy-MM-dd"
                            minDate={new Date()}
                        />
                    )}

                    <br/><br/>
                    <p>{translations.availability?.to}:</p>

                    <DatePicker
                        selected={endDate} name="todate"
                        onChange={(date: Date) => setEndDate(date)} className="to-date-picker"
                        selectsEnd
                        locale={calendarLocale}
                        startDate={new Date(selectedDate!)}
                        endDate={endDate}
                        dateFormat="yyyy-MM-dd"
                        maxDate={new Date(nextArrivalDate!)}
                        minDate={new Date(addDays(selectedDate!, 1))}
                    />

                    <br/>
                    <br/>
                    <p
                        style={{color: "rgba(0, 0, 0, 0.54)"}}>{nrOfDays > 0 && `${nrOfDays} ${nrOfDays === 1 ? translations.common?.night : nrOfDays > 1 ? translations.common?.nights : ""}`}</p>


                    <>
                        <hr/>
                        <h3>{translations.common?.guest}</h3>
                        <br/>

                        <div style={{display: "grid", gridAutoFlow: isTabletOrMobile ? "row" : "column", gap: "20px"}}>
                            <div>
                                <label>{translations.common?.firstname}</label>
                                <input id="guestfirstname"
                                       placeholder={guestIsOwner ? userContext.user?.firstName : "Firstname"}
                                       disabled={loading || guestIsOwner} required={!guestIsOwner}
                                       className="form-control form-control-lg" type="text" name="firstname"/>
                            </div>

                            <div style={{gridRow: isTabletOrMobile ? 2 : 1}}>
                                <label>{translations.common?.lastname}</label>
                                <input id="guestlastname"
                                       placeholder={guestIsOwner ? userContext.user?.lastName : "Lastname"}
                                       disabled={loading || guestIsOwner} required={!guestIsOwner}
                                       className="form-control form-control-lg" type="text" name="lastname"/>
                            </div>

                            <div style={{gridRow: isTabletOrMobile ? 3 : 2, gridColumn: 1}}>

                                <label>{translations.common?.nrOfGuests}</label>
                                <select id="nrofguests" name="nrofguests" className="form-control form-control-lg">
                                    {selectedObject !== undefined && renderNumberOfGuestOptions()}
                                </select>


                            </div>

                            <div style={{
                                gridColumn: isTabletOrMobile ? 1 : 2,
                                gridRow: isTabletOrMobile ? 4 : 2,
                                display: "grid",
                                alignItems: "center",
                                justifyItems: "start"
                            }}>
                                <div>
                                    <label style={{marginBottom: "0.2rem"}}>{translations.common?.guestIsOwner}</label>
                                    <input disabled={loading} name="guestisowner"
                                           onChange={(newVal: any) => updateGuestIsOwner(newVal.target.checked)}
                                           style={{verticalAlign: "middle", marginLeft: "10px"}} type="checkbox"/>
                                </div>
                            </div>

                        </div>
                    </>


                </form>
            </DialogContent>
            <DialogActions style={{paddingLeft: "24px", paddingRight: "24px", paddingBottom: "15px"}}>
                <MantineButton disabled={loading} onClick={() => onClose()} variant="secondary"
                               style={{minWidth: "60px"}}>
                    {translations.common?.cancel}
                </MantineButton>
                <MantineButton
                    onClick={SaveBooking}
                    disabled={loading} style={{minWidth: "80px"}}>
                    {loading && <Loader size="sm" role="status" aria-hidden="true"
                                        style={{marginRight: "5px", verticalAlign: "middle"}}/>}
                    {loading && translations.common?.saving}
                    {!loading && translations.common?.save}
                </MantineButton>
            </DialogActions>
        </Dialog>
    )
}

export default NewBookingModal