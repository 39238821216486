import React, {useLayoutEffect, useState} from "react"

// Components
import {Login} from "./pages/login"
import {AdminLogin} from "./pages/admin_login"
import {AssumeUser} from "./pages/assume_user"
import {LandingPage} from "./pages/landing_page"
import {SetPassword} from "./pages/set_password"
import {HashRouter, Route, Routes} from "react-router-dom"
import {PrivateRoute} from "./private_route"
import {UC, UserContext} from "./usercontext"
import {TranslationContext} from "./translationcontext"
import {Loader, MantineProvider} from "@mantine/core"
import {ModalsProvider} from "@mantine/modals"
import {QueryClient, QueryClientProvider} from "@tanstack/react-query"

// API
import * as API from "./api"


// CSS
import "./App.css"
import {OwnerPortalTranslations, UserModel, UserRoles} from "./generated_api"


const queryClient = new QueryClient()


export default function App() {
    const [signedInUser, setSignedInUser] = useState<UC>(getSignedInUser)
    const [translations, setTranslations] = useState<OwnerPortalTranslations>({} as OwnerPortalTranslations)
    const [loading, setLoading] = useState(false)

    const loginSuccessful = (user: UserModel) => setSignedInUser((current) => ({
        ...current,
        user: user
    }))

    const Logout = () => {
        localStorage.removeItem("bv_op_userinfo")
        setSignedInUser((current) => ({...current, user: undefined}))
    }

    const shouldRefetchTranslations = React.useMemo(
        () => signedInUser.user === undefined || translations.langId === undefined,
        [signedInUser, translations.langId]
    )

    useLayoutEffect(() => {
        API.Methods.logout = Logout
        if (shouldRefetchTranslations) {
            setLoading(true)
            API.GetTranslations(undefined, true).then(result => {
                setTranslations(result)
                setLoading(false)
            })
        }
    }, [shouldRefetchTranslations])

    function getSignedInUser() {
        let userInfo = localStorage.getItem("bv_op_userinfo")
        return {
            user: userInfo !== null ? JSON.parse(userInfo) : undefined,
            logout: () => {
            }
        }
    }

    if (loading || translations.langId === undefined) {
        return <div style={{display: "grid", alignContent: "center", height: "100%"}}>
            <Loader size="sm" role="status" aria-hidden="true" style={{
                height: "50px",
                width: "50px",
                marginRight: "5px",
                justifySelf: "center",
                verticalAlign: "middle",
                color: "#0f464a"
            }}/>
        </div>
    }

    const hasFullAccess = signedInUser.user?.bvUser?.roles?.includes(UserRoles.OwnersPortalFullAccess)
    const isBVUser = signedInUser.user?.bvUser !== undefined && signedInUser.user?.bvUser !== null

    return (
        <HashRouter>
            <QueryClientProvider client={queryClient}>
                <UserContext.Provider value={{
                    hasFullAccess,
                    isBVUser,
                    user: signedInUser.user,
                    logout: Logout,
                    updateContext: (newUserObject: UserModel) => {
                        localStorage.setItem("bv_op_userinfo", JSON.stringify({
                            ...newUserObject,
                            timeOfAuth: signedInUser!.user!.timeOfAuth
                        }))
                        setSignedInUser((current) => ({
                            ...current,
                            user: {...newUserObject, timeOfAuth: signedInUser!.user!.timeOfAuth}
                        }))
                    }
                }}>
                    <TranslationContext.Provider value={translations}>
                        <MantineProvider>
                            <ModalsProvider>
                                <Routes>
                                    <Route path="/login/:orgId" element={<Login LoginSuccessful={loginSuccessful}/>}/>
                                    <Route path="/login" element={<Login LoginSuccessful={loginSuccessful}/>}/>
                                    <Route path="/adminlogin"
                                           element={<AdminLogin LoginSuccessful={loginSuccessful}/>}/>
                                    <Route path="/assumeuser"
                                           element={<AssumeUser LoginSuccessful={loginSuccessful}/>}/>
                                    <Route path="/createpassword/:token" element={<SetPassword FirstTime={true}/>}/>
                                    <Route path="/forgotpassword" element={<SetPassword/>}/>
                                    <Route path="/forgotpassword/:orgId" element={<SetPassword IsOrgLink={true}/>}/>
                                    <Route path="/*" element={
                                        <PrivateRoute path="/">
                                            <LandingPage
                                                UpdateTranslations={(newTranslations: OwnerPortalTranslations) => setTranslations(newTranslations)}/>
                                        </PrivateRoute>
                                    }/>
                                </Routes>
                            </ModalsProvider>
                        </MantineProvider>
                    </TranslationContext.Provider>
                </UserContext.Provider>
            </QueryClientProvider>
        </HashRouter>
    )
}