import { useQuery } from "@tanstack/react-query"
import * as API from "../api"

export const useFinanceData = (selectedYear: string, selectedObject?: string) => {
  return useQuery(
    ["financeData", selectedYear, selectedObject],
    async () => {
      if (selectedObject !== "" && selectedObject !== undefined) {
        const yearInt = parseInt(selectedYear)
        return await API.GetFinanceData(yearInt, selectedObject)
      } else {
        return undefined
      }
    },
    {
      enabled: Boolean(selectedYear) && Boolean(selectedObject),
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  )
}