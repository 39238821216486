import React, { FC, useContext, useEffect, useState } from "react"

// Components
import { ArrowLeft, ArrowRight, Ban, CalendarPlus } from "./icons"
import { Loader, Menu } from "@mantine/core"
import { TranslationContext } from "../translationcontext"
import { UserContext } from "../usercontext"

// API
import { GetReservationsAndRestrictions } from "../api"

// Lib
import {
  dotNetToJSDate,
  getDaysBetweenDates,
  getDaysInMonthUTC,
  getMonthDate,
  getWeekNumber,
  monthNumberToString,
  useIsMounted
} from "../helpers"

import { AvailabilityCalendarProps } from "./interfaces"
import { GuestRoomRestriction, OwnerPortalReservation, ReservationsAndRestrictions } from "../generated_api"

const translator = new Intl.DateTimeFormat("sv-SE")


export const AvailabilityCalendar: FC<AvailabilityCalendarProps> = (
  {
    ImageUrl, DataFetchFailed, IsLoading,
    bookingClicked, newBookingClicked, newBlockClicked,
    selectedObjectId, selectedMonth, onMonthChanged,
    SetIsLoading, removeBlockClicked, ReloadTrigger
  }) => {
  const translations = useContext(TranslationContext)
  const [days, setDays] = useState<{ lastMonth: Date[], thisMonth: Date[], nextMonth: Date[] }>({
    lastMonth: [],
    thisMonth: [],
    nextMonth: []
  })
  const [reservationsAndRestrictions, setReservationsAndRestrictions] = useState<ReservationsAndRestrictions>({
    reservations: [],
    restrictions: []
  })
  const [allUnavailable, setAllUnavailable] = useState(false)
  const {hasFullAccess, isBVUser} = useContext(UserContext)

  const isMounted = useIsMounted()

  useEffect(() => {
    if (!isMounted.current) {
      return
    }
    let month = getMonthDate(selectedMonth);
    let [days, daysLastMonth, daysNextMonth] = getDaysInMonthUTC(month.getMonth(), month.getFullYear())

    if (isMounted.current) {
      setReservationsAndRestrictions({ reservations: [], restrictions: [] })
      setDays({
        lastMonth: daysLastMonth,
        thisMonth: days,
        nextMonth: daysNextMonth
      })
    }
  }, [selectedMonth, isMounted])

  useEffect(() => {
    if (!isMounted.current) {
      return
    }
    if (days.thisMonth.length > 0 && selectedObjectId !== undefined) {
      if (isMounted.current) {
        SetIsLoading(true)
      }

      GetReservationsAndRestrictions(
        translator.format(days.lastMonth[days.lastMonth.length - 15]),
        translator.format(days.nextMonth[15]),
        selectedObjectId,
        false,
        true
      ).then(result => {
        if (result === undefined) {
          if (isMounted.current) {
            setAllUnavailable(true)
          }
          DataFetchFailed("Failed to fetch data", "#d23a3a")
        } else if ("error" in result) {
          if (isMounted.current) {
            setAllUnavailable(true)
          }
          DataFetchFailed(result.error, "#d23a3a")
        } else if ("reservations" in result) {
          if (allUnavailable && isMounted.current) {
            setAllUnavailable(false)
          }

          result.reservations?.forEach(x => {
            x.arrival = dotNetToJSDate(x.arrival!)
            x.departure = dotNetToJSDate(x.departure!)
          })
          if (selectedObjectId && isMounted.current) {
            setReservationsAndRestrictions({
              reservations: result.reservations?.filter(x => x.roomTypeId === selectedObjectId),
              restrictions: result.restrictions
            })
          }
        }

        if (isMounted.current) {
          SetIsLoading(false)
        }
      })
    }
  }, [days, ReloadTrigger, allUnavailable, isMounted, selectedObjectId, SetIsLoading, DataFetchFailed])

  const DateAvailableForBookingTile =
    ({
       day,
       stayOverBookingThisDay,
       arrivalBookingThisDay,
       arrivalTomorrow,
       daysTilDeparture,
       isDoubleBooked,
       allBookings,
       allRestrictions
     }:
       {
         day: Date,
         stayOverBookingThisDay: OwnerPortalReservation | undefined,
         arrivalBookingThisDay: OwnerPortalReservation | undefined,
         arrivalTomorrow: any,
         daysTilDeparture: number,
         isDoubleBooked: boolean,
         allBookings: OwnerPortalReservation[],
         allRestrictions: GuestRoomRestriction
       }): JSX.Element => {
      if (translator.format(day) < translator.format(new Date())) {
        return <></>
      }
      let className = ""
      if (stayOverBookingThisDay === undefined && arrivalBookingThisDay === undefined) {
        className = "bv-booked bv--1 free-before-after"
      } else if (arrivalBookingThisDay === undefined && arrivalTomorrow === undefined && (stayOverBookingThisDay === undefined || daysTilDeparture === 0)) {
        className = "bv-booked bv--1 booked-before"
      } else if (arrivalBookingThisDay === undefined && (stayOverBookingThisDay === undefined || daysTilDeparture === 0)) {
        className = "bv-booked bv--1"
      }

      if (isDoubleBooked) {
        className = ""
      }

      if (className !== "") {


        const nextArrival =
          allBookings.slice()
            .filter(x => x.arrival! > day)
            .sort(
              (b, a) => b.arrival!.getTime() / 1000 - a.arrival!.getTime() / 1000
            )[0]
        let nextArrivalDate = nextArrival?.arrival
        let nextRestrictionDate = allRestrictions?.rateRestrictions?.find(w => (w.closed && !w.closedByOwner) && new Date(w.date!) > day)?.date
        if (nextArrivalDate === undefined && nextRestrictionDate !== undefined) {
          nextArrivalDate = nextRestrictionDate
        } else if (nextRestrictionDate !== undefined && new Date(nextRestrictionDate) < nextArrivalDate!) {
          nextArrivalDate = nextRestrictionDate
        }

        return (
          <>
            <Menu position="top" withinPortal>
              <Menu.Target key={`${day.toLocaleDateString("sv-SE")}_cbo`}>
                <div className={className}>
                  <div id="free-day">
                    <div className="bv-about">
                      <div className="bv-name" style={{ fontSize: "0.9em" }}></div>
                    </div>
                  </div>
                </div>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  unstyled
                  disabled={isBVUser && !hasFullAccess}
                  style={{ cursor: "pointer", userSelect: "none", border: "none" }}
                  onClick={() => {
                    newBookingClicked(day, false, nextArrivalDate, nextRestrictionDate)
                  }} className="list-group-item">
                  <CalendarPlus />
                  <p style={{
                    display: "inline",
                    verticalAlign: "middle",
                    marginLeft: "5px"
                  }}>{translations.availability?.newBooking} {isBVUser && !hasFullAccess && `(${translations.common?.disabledInAssumeMode})`}</p>
                </Menu.Item>

                <Menu.Divider />

                <Menu.Item
                  unstyled
                  disabled={isBVUser && !hasFullAccess}
                  style={{ cursor: "pointer", userSelect: "none", border: "none" }}
                  onClick={() => {
                    newBlockClicked(day, true, nextArrivalDate, nextRestrictionDate)
                  }} className="list-group-item">
                  <Ban />
                  <p style={{
                    display: "inline",
                    verticalAlign: "middle",
                    marginLeft: "5px"
                  }}>{translations.availability?.newBlock} {isBVUser && !hasFullAccess && `(${translations.common?.disabledInAssumeMode})`}</p>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </>
        )
      } else {
        return <></>
      }
    }


  const WeekdaysRow = (weekNumber: number, days: Date[], bookings: OwnerPortalReservation[]) => {
    return (
      <div key={weekNumber} className="bv-row bv--week-days">
        <div className="bv-week">
          <div>
            {weekNumber}
          </div>
        </div>
        {days.map((day, index) => {
          let formattedDay = translator.format(day)
          let restrictionsForThisRoom = reservationsAndRestrictions.restrictions?.find(x => x.guestRoomId === selectedObjectId)
          let restrictedThisDay = restrictionsForThisRoom?.rateRestrictions?.find(r =>
            translator.format(dotNetToJSDate(r.date!)) === formattedDay && r.closed === true
          )
          let arrivalBookingThisDay = bookings.find(x => translator.format(x.arrival) === translator.format(day))
          let stayOverBookingThisDay = bookings.find(x => translator.format(x.arrival) < translator.format(day) && translator.format(x.departure) >= translator.format(day))
          let stayLength = arrivalBookingThisDay !== undefined ? getDaysBetweenDates(arrivalBookingThisDay.arrival!, arrivalBookingThisDay.departure!).length : 0
          let arrivalClassName = `bv-booked bv--${stayLength > 7 ? "7" : stayLength} ${arrivalBookingThisDay?.channel === "OwnersPortal" ? "bv-calmarker-owner" : arrivalBookingThisDay?.channel === "blocked" ? `bv-calmarker-blocked` : "bv-calmarker-default"} clickable`
          let stayOverClassName = `bv-booked bv--0 ${stayOverBookingThisDay?.channel === "OwnersPortal" ? "bv-calmarker-owner" : stayOverBookingThisDay?.channel === "blocked" ? `bv-calmarker-blocked` : "bv-calmarker-default"} clickable`

          const nextArrival =
            reservationsAndRestrictions.reservations?.slice()
              .filter(x => x.arrival! > day)
              .sort(
                (b, a) => b.arrival!.getTime() / 1000 - a.arrival!.getTime() / 1000
              )[0]

          let nextArrivalDate = nextArrival?.arrival
          let nextRestrictionDate = restrictionsForThisRoom?.rateRestrictions?.find(w => (w.closed && !w.closedByOwner) && new Date(w.date!) > day)?.date
          if (nextArrivalDate === undefined && nextRestrictionDate !== undefined) {
            nextArrivalDate = nextRestrictionDate
          } else if (nextRestrictionDate !== undefined && new Date(nextRestrictionDate) < nextArrivalDate!) {
            nextArrivalDate = nextRestrictionDate
          }

          let arrivalWasAnotherWeek = stayOverBookingThisDay !== undefined
            && (getWeekNumber(stayOverBookingThisDay.arrival!) < getWeekNumber(day)
              || getWeekNumber(stayOverBookingThisDay.arrival!) > getWeekNumber(day))

          let daysTilDeparture = stayOverBookingThisDay !== undefined ? getDaysBetweenDates(day, stayOverBookingThisDay.departure!).length : 0

          let tomorrowDate = new Date(day.getTime())
          tomorrowDate.setDate(tomorrowDate.getDate() + 1)
          let arrivalTomorrow = bookings.find(x => translator.format(x.arrival) === translator.format(tomorrowDate))

          let isDoubleBooked = bookings.filter(x => translator.format(x.arrival) < translator.format(day) && translator.format(x.departure) >= translator.format(day)).length > 1

          let isLeavingToday = translator.format(stayOverBookingThisDay?.departure) === translator.format(day)

          if (allUnavailable || ((isLeavingToday || stayOverBookingThisDay === undefined) && arrivalBookingThisDay === undefined && restrictedThisDay && day.toLocaleDateString("sv-SE") >= new Date().toLocaleDateString("sv-SE"))) {
            if (restrictedThisDay?.closedByOwner) {
              return (
                <>
                  <Menu position="top" withinPortal>
                    <Menu.Target key={`${day.toLocaleDateString("sv-SE")}_cbo`}>
                      <div className="bv-day bv-calmarker-notavail-owner">
                        <span className="bv-date">
                        {day.getDate()}
                        </span>
                      </div>
                    </Menu.Target>

                    <Menu.Dropdown>
                      <Menu.Item
                        disabled={isBVUser && !hasFullAccess}
                        style={{ cursor: "pointer", userSelect: "none" }}
                        onClick={() => newBookingClicked(day, false, nextArrivalDate, nextRestrictionDate)}>
                        <CalendarPlus />
                        <p style={{
                          display: "inline",
                          verticalAlign: "middle",
                          marginLeft: "5px"
                        }}>{translations.availability?.newBooking} {isBVUser && !hasFullAccess && `(${translations.common?.disabledInAssumeMode})`}</p>
                      </Menu.Item>

                      <Menu.Divider />

                      <Menu.Item
                        disabled={isBVUser && !hasFullAccess}
                        style={{ cursor: "pointer", userSelect: "none" }} onClick={() => {
                        removeBlockClicked(day, false, nextArrivalDate, nextRestrictionDate)
                      }}>
                        <Ban />
                        <p style={{
                          display: "inline",
                          verticalAlign: "middle",
                          marginLeft: "5px"
                        }}>{translations.availability?.removeBlock} {isBVUser && !hasFullAccess && `(${translations.common?.disabledInAssumeMode})`}</p>
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </>
              )
            } else {
              return (
                <div key={`${day.toLocaleDateString("sv-SE")}_closed`} style={{ userSelect: "none" }}
                     className={`bv-day bv-calmarker-notavail`}>
                                    <span className="bv-date">
                                        {day.getDate()}
                                    </span>
                </div>
              )
            }


          }

          let cName = "bv-day"
          if (translator.format(day) < translator.format(new Date())) {
            cName = "bv-day bv-day-past"
          }

          return (
            <React.Fragment key={`${translator.format(day)}_${index}`}>
              <div style={{ userSelect: "none" }} className={cName}>
                {arrivalWasAnotherWeek && index === 0 &&
                  <div onClick={() => bookingClicked(stayOverBookingThisDay!)}
                       id={stayOverBookingThisDay!.bookingVersionPublicId} className={stayOverClassName}
                       style={{ width: `${40 + (100 * daysTilDeparture)}%` }}>
                    <div>
                      {daysTilDeparture > 0 && <div className="bv-about">
                        <div className="bv-channel"
                             style={{ backgroundImage: `url(${stayOverBookingThisDay!.channelLogoImageUrl || `${ImageUrl}${stayOverBookingThisDay!.channelLogoImageId}.png`})` }}></div>
                          <div className="bv-name">{stayOverBookingThisDay?.isCustomerDataHidden ? stayOverBookingThisDay.bookingCode : stayOverBookingThisDay!.customerName}</div>
                      </div>}
                    </div>
                  </div>}
                {arrivalBookingThisDay !== undefined && <div key={arrivalBookingThisDay!.bookingVersionPublicId}
                                                             onClick={() => bookingClicked(arrivalBookingThisDay!)}
                                                             className={arrivalClassName}
                                                             id={arrivalBookingThisDay!.bookingVersionPublicId}>
                  <div>
                    <div className="bv-about">
                      {arrivalBookingThisDay.channel !== "blocked" && <div className="bv-channel"
                                                                           style={{ backgroundImage: `url(${arrivalBookingThisDay!.channelLogoImageUrl || `${ImageUrl}${arrivalBookingThisDay!.channelLogoImageId}.png`})` }}></div>}
                      <div className="bv-name">{arrivalBookingThisDay?.isCustomerDataHidden ? arrivalBookingThisDay.bookingCode : arrivalBookingThisDay!.customerName}</div>
                    </div>
                  </div>

                </div>}
                {!IsLoading && <DateAvailableForBookingTile isDoubleBooked={isDoubleBooked}
                                                            arrivalBookingThisDay={arrivalBookingThisDay}
                                                            arrivalTomorrow={arrivalTomorrow} day={day}
                                                            daysTilDeparture={daysTilDeparture}
                                                            stayOverBookingThisDay={stayOverBookingThisDay}
                                                            allBookings={bookings}
                                                            allRestrictions={restrictionsForThisRoom!} />}
                <span className="bv-date">
                                    {day.getDate()}
                                </span>
              </div>
            </React.Fragment>
          )
        })}
      </div>
    )
  }


  const renderWeekRows = () => {
    if (days.thisMonth.length === 0) {
      return
    }

    let daysArr = [...days.thisMonth]
    let daysLastMonthArr = [...days.lastMonth]
    let daysNextMonthArr = [...days.nextMonth]
    let res = []

    while (daysArr[0].getDay() !== 1) {
      let lastDayLastMonth = daysLastMonthArr.pop()
      daysArr.unshift(lastDayLastMonth!)
    }

    let lastWeekThisMonth = getWeekNumber(days.thisMonth.slice(-1)[0])

    let nrOfDaysLastWeekInMonth = daysArr.filter(x => getWeekNumber(x) === lastWeekThisMonth)

    let missingDays = 7 - nrOfDaysLastWeekInMonth.length

    for (let i = 0; i < missingDays; i++) {
      daysArr.push(daysNextMonthArr.shift()!)
    }

    while (daysArr.length > 0) {
      let weekNr = getWeekNumber(daysArr[0])
      res.push(WeekdaysRow(weekNr, daysArr.splice(0, 7), reservationsAndRestrictions.reservations!))
    }
    return res
  }

  const DayNameHeader = () => {
    return (

      <div className="bv-row bv--week-name">
        <div className="bv-week">
          <span className="bv-long">{translations.availability?.week}</span>
          <span className="bv-short">{translations.availability?.week}</span>
        </div>
        <div className="bv-day">
          <span className="bv-long">{translations.availability?.monday}</span>
          <span className="bv-short">{translations.availability?.mondayThreeLetters}</span>
        </div>
        <div className="bv-day">
          <span className="bv-long">{translations.availability?.tuesday}</span>
          <span className="bv-short">{translations.availability?.tuesdayThreeLetters}</span>
        </div>
        <div className="bv-day">
          <span className="bv-long">{translations.availability?.wednesday}</span>
          <span className="bv-short">{translations.availability?.wednesdayThreeLetters}</span>
        </div>
        <div className="bv-day">
          <span className="bv-long">{translations.availability?.thursday}</span>
          <span className="bv-short">{translations.availability?.thursdayThreeLetters}</span>
        </div>
        <div className="bv-day">
          <span className="bv-long">{translations.availability?.friday}</span>
          <span className="bv-short">{translations.availability?.fridayThreeLetters}</span>
        </div>
        <div className="bv-day">
          <span className="bv-long">{translations.availability?.saturday}</span>
          <span className="bv-short">{translations.availability?.saturdayThreeLetters}</span>
        </div>
        <div className="bv-day">
          <span className="bv-long">{translations.availability?.sunday}</span>
          <span className="bv-short">{translations.availability?.sundayThreeLetters}</span>
        </div>
      </div>
    )
  }

  const GoBackOneMonth = () => {
    SetIsLoading(true)
    let currentDate = getMonthDate(selectedMonth)

    let year = currentDate.getFullYear()
    let monthNr = currentDate.getMonth();
    if (monthNr < 1) {
      monthNr = 12
      year -= 1
    }

    onMonthChanged(`${year}-${monthNr.toString().padStart(2, "0")}`)
  }

  const GoForwardOneMonth = () => {
    SetIsLoading(true)
    let currentDate = getMonthDate(selectedMonth)
    let year = currentDate.getFullYear()
    let monthNr = currentDate.getMonth() + 2
    if (monthNr > 12) {
      monthNr = 1
      year += 1
    }

    onMonthChanged(`${year}-${monthNr.toString().padStart(2, "0")}`)
  }

  return (
    <div>
      <div className="vg-panel"
           style={{ opacity: IsLoading ? "0.5" : "1", userSelect: "none" }}
      >
        <div className="vg-panel-header">


          <div className="vg-panel-title">
            <h3>{monthNumberToString(getMonthDate(selectedMonth).getMonth(), translations)} {getMonthDate(selectedMonth).getFullYear()}</h3>
          </div>
          <div className="vg-actions">
            {IsLoading &&
              <Loader style={{ verticalAlign: "middle", marginRight: "10px" }} />}

            <button disabled={IsLoading} onClick={GoBackOneMonth} className="btn btn-secondary" type="button">
                            <span className="vg-icon vg-icon-only">
                                <ArrowLeft />
                            </span>
            </button>

            <button disabled={IsLoading} onClick={GoForwardOneMonth} className="btn btn-secondary" type="button">
                            <span className="vg-icon vg-icon-only">
                                <ArrowRight />
                            </span>
            </button>
          </div>
        </div>

        <div className="bv-avail-calendar">
          <div className="bv-inner">
            <DayNameHeader />
            {renderWeekRows()}
          </div>
        </div>
      </div>

      <div className="bv-legends">
        <div className="bv-legend">
          <div className="bv-calmarker-default">
            <div></div>
          </div>
          <div>{translations.availability?.booked}</div>
        </div>
        <div className="bv-legend">
          <div className="bv-calmarker-owner">
            <div></div>
          </div>
          <div>{translations.availability?.yourBooking}</div>
        </div>
        <div className="bv-legend">
          <div className="bv-calmarker-notavail">
            <div></div>
          </div>
          <div>{translations.availability?.notAvailForBooking}</div>
        </div>
        <div className="bv-legend">
          <div className="bv-calmarker-past-date">
            <div></div>
          </div>
          <div>{translations.availability?.pastDate}</div>
        </div>
        <div className="bv-legend">
          <div className="bv-calmarker-notavail-owner">
            <div></div>
          </div>
          <div>{translations.common?.ownersBlock}</div>
        </div>
      </div>
    </div>
  )
}