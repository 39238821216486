import { createContext } from "react"
import { UserModel } from "./generated_api"

export interface UC {
  user: UserModel | undefined,
  logout: Function,
  updateContext?: (newUserObject: UserModel) => void,
  hasFullAccess?: boolean,
  isBVUser?: boolean
}

export const UserContext = createContext<UC>({
  user: undefined, logout: () => {
  }
})