import React, { FC, useContext, useState } from "react"

// Components
import { TranslationContext } from "../translationcontext"
import { useMediaQuery } from "react-responsive"
import { Button, Drawer, Loader } from "@mantine/core"
import { UserContext } from "../usercontext"

// API
import { CancelReservation as APICancelReservation } from "../api"

// Lib
import { currencyFormatter, getDaysBetweenDates } from "../helpers"

// Types
import { BookingDetailsModalProps } from "./interfaces"
import { OwnerPortalGuest, OwnerPortalGuestType } from "../generated_api"


export const BookingDetailsModal: FC<BookingDetailsModalProps> = (
  {
    booking, imageUrl, showDrawer,
    onClose, onReservationCancelled
  }) => {
  const translations = useContext(TranslationContext)
  const userContext = useContext(UserContext)
  const [isCanceling, setIsCanceling] = useState(false)
  const [showConfirmCancelButtons, setShowConfirmCancelButton] = useState(false)

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 500px)" })

  const isBVWebBooking = booking?.isBVWebBookingChannel
  const isOwnersBooking = booking?.channel?.toLocaleLowerCase() === "ownersportal"
  const isExternalBooking = isBVWebBooking === false && !isOwnersBooking
  const hasDeparturePassed = booking?.departure !== undefined && booking.departure < new Date()

  const arrivalDepartureString = () => {
    if (!booking) {
      return
    }
    let amountOfNights = getDaysBetweenDates(booking?.arrival!, booking?.departure!).length

    return booking?.arrival !== undefined && booking?.departure !== undefined ?
      `${booking?.arrival.toDateString()} - ${booking.departure.toDateString()}, ${amountOfNights} ${amountOfNights > 1 ? translations.common?.nights : translations.common?.night}`
      : ""
  }

  const adultString = () => {
    let nrOfAdults = booking?.guests?.filter((g: OwnerPortalGuest) =>
      g.typeOfGuest === OwnerPortalGuestType.Adult
    ).length ?? 0
    return `${nrOfAdults} ${translations.common?.adult}`
  }
  const childrenString = () => {
    let children = booking?.guests?.filter(g =>
      g.typeOfGuest === OwnerPortalGuestType.Child
    )
    if (children?.length === 0) return ""

    return `, ${children?.length} ${translations.common?.child} (${children?.map(x => x.age).join(", ")} ${translations.common?.years})`
  }
  const guestsString = () =>
    `${adultString()}${childrenString()}`

  const CancelReservation = async () => {
    setIsCanceling(true)
    setShowConfirmCancelButton(false)
    let result = await APICancelReservation(booking?.bookingCode!, booking!.roomTypeId!)
    setIsCanceling(false)
    onReservationCancelled(result === true)
  }

  return <>

    <Drawer
      position="right"
      className="booking-drawer"
      size={isTabletOrMobile ? "100%" : "400px"}
      opened={showDrawer || isCanceling} onClose={() => onClose()}
    >
      <div className="modal-content">
        <div className="vg-panel">
          <div className="vg-panel-header">
            <div className="vg-panel-title">
              <div className="small text-muted">{translations.common?.booking} #</div>
              <h3>{booking?.bookingCode}</h3>
            </div>
            <button type="button" disabled={isCanceling} className="close" onClick={() => onClose()}
                    data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="vg-panel-content">

            <div className="vg-form-section">
              <div>
                {!booking?.isCustomerDataHidden && (
                    <>
                      <div className="small text-muted">{translations.common?.customerName}:</div>
                      <div>{booking?.customerName}</div>
                    </>
                )}

                <div className="small text-muted"
                     style={{ marginTop: "5px" }}>{translations.common?.arrival} / {translations.common?.departure} / {translations.common?.noOfNights}</div>
                <div>{booking && arrivalDepartureString()}</div>

                <div className="small text-muted"
                     style={{ marginTop: "5px" }}>{translations.common?.guests}</div>
                <div>{booking && guestsString()}</div>

                <div className="vg-simple-grid align-middle vg-gutter">
                  <div className="vg-fullwidth">
                    <div className="small text-muted"
                         style={{ marginTop: "5px" }}>{translations.common?.bookingChannel}</div>
                    {isBVWebBooking && <div>{booking?.hotelname}</div>}
                    {isOwnersBooking && <div>{translations.common?.owner}</div>}
                    {isExternalBooking && <div>{booking?.channel}</div>}
                  </div>
                  {booking && <div className="bv-channel"
                                   style={{ backgroundImage: `url(${booking.channelLogoImageUrl || `${imageUrl}${booking.channelLogoImageId}.png`})` }}></div>}
                </div>
              </div>
            </div>

            <div className="vg-form-section">
              <table className="table-text vg-fullwidth">

                <tbody>
                <tr>
                  <td className="text-muted"><small>{translations.common?.salesAmountIncVAT}:</small>
                  </td>
                  <td
                    className="text-right">{booking?.currency && currencyFormatter(booking?.currency, booking?.salesAmountIncVAT!)}</td>
                </tr>
                <tr>
                  <td className="text-muted"><small>{translations.common?.discount}:</small></td>
                  <td
                    className="text-right">{booking?.currency && currencyFormatter(booking?.currency!, booking?.discount!)}</td>
                </tr>
                <tr>
                  <td className="text-muted"><small>{translations.common?.channelCost}:</small></td>
                  <td
                    className="text-right">{booking?.currency && currencyFormatter(booking?.currency!, booking?.channelCost!)}</td>
                </tr>
                <tr>
                  <td className="text-muted"><small>{translations.common?.cleaning}:</small></td>
                  <td
                    className="text-right">{booking?.currency && currencyFormatter(booking?.currency!, booking?.cleaningCost!)}</td>
                </tr>
                <tr>
                  <td className="text-muted"><small>{translations.common?.subTotal}:</small></td>
                  <td
                    className="text-right">{booking?.currency && currencyFormatter(booking?.currency!, booking?.subTotal!)}</td>
                </tr>
                <tr>
                  <td style={{ height: "10px" }}></td>
                  <td></td>
                </tr>
                <tr>
                  <td className="text-muted"><small><b>{translations.common?.operatorFee}:</b></small>
                  </td>
                  <td className="text-right">
                    <b>{booking?.currency && currencyFormatter(booking?.currency!, booking?.ownerCommissionAmount!)}</b>
                  </td>
                </tr>
                <tr>
                  <td className="text-muted"><small><b>{translations.common?.ownerRevenue}:</b></small>
                  </td>
                  <td className="text-right">
                    <b>{booking?.currency && currencyFormatter(booking?.currency!, booking.salesAmountIncVAT! - booking.cleaningCost! - booking.channelCost! - booking.ownerCommissionAmount!)}</b>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>


        </div>
        {showConfirmCancelButtons && <>
          <h3 style={{ alignSelf: "center" }}>{translations.common?.cancelReservationConfirmationQuestion}</h3>
          <div style={{
            textAlign: "center",
            display: "grid",
            gridAutoFlow: "column",
            gridGap: "10px",
            marginLeft: "10px",
            marginRight: "10px",
            marginTop: "10px"
          }}>

            <Button onClick={CancelReservation}>{translations.common?.yesContinue}</Button><Button
            variant="outline-secondary"
            onClick={() => setShowConfirmCancelButton(false)}>{translations.common?.noCancel}</Button>
          </div>
        </>}
        {!showConfirmCancelButtons && isOwnersBooking && <Button
          disabled={isCanceling || hasDeparturePassed || (userContext.user?.bvUser !== undefined && userContext.user?.bvUser !== null)}
          onClick={() => setShowConfirmCancelButton(true)}
          style={{ width: "80%", height: "40px", alignSelf: "center" }}>
          {isCanceling && <Loader size="sm" role="status" aria-hidden="true"
                                  style={{ marginRight: "5px", verticalAlign: "middle" }} />}

          {isCanceling
            ? <span>{translations.common?.cancelingReservation}...</span>
            : <span>{translations.common?.cancelReservation}</span>}
        </Button>}
      </div>
    </Drawer>
  </>

}
