import {
  AccountInfo,
  GuestRoomInfo,
  OrganizationContactInfo,
  OwnerPortalFinanceData,
  OwnerPortalReservation,
  PaymentInfo,
  RateUpdate,
  ReservationsAndRestrictions,
  UserModel
} from "./generated_api"
import { ErrorMessage } from "./models/error_message"

// eslint-disable-next-line no-extend-native
Date.prototype.toJSON = function () {
  const hoursDiff = this.getHours() - this.getTimezoneOffset() / 60
  this.setHours(hoursDiff)
  return this.toLocaleDateString("sv-SE")
}

declare global {
  interface Window {
    runConfig: any
  }
}

let baseUrl = window.runConfig.apiUrl

const PostJson = async (
  url: string,
  body: string,
  customHeaders?: any,
  noAuth?: boolean,
  errorString: string = "Failed"
) => {
  let userInfo: UserModel = noAuth ? null : JSON.parse(localStorage.getItem("bv_op_userinfo")!)

  try {
    let result = await fetch(`${baseUrl}${url}`, {
      method: "post",
      mode: "cors",
      headers: noAuth
        ? { "Content-Type": "application/json" }
        : {
            Authorization: `Bearer ${userInfo.token}`,
            "Content-Type": "application/json"
          },
      body: body
    })

    if (result.status === 200) {
      return await result.json()
    } else if (result.status === 401) {
      console.log("401 Unauthorized, signing out..")
      Methods.logout()
    }

    try {
      let res = await result.json()
      if ("message" in res) {
        return new Error(res.message)
      }
    } catch (e) {
      return new Error(errorString)
    }
  } catch (e) {
    return e
  }
}

const GetRequest = async (url: string, customHeaders?: any, noAuth?: boolean) => {
  let userInfo: UserModel = noAuth ? null : JSON.parse(localStorage.getItem("bv_op_userinfo")!)

  try {
    let result = await fetch(`${baseUrl}${url}`, {
      method: "get",
      mode: "cors",
      headers: noAuth
        ? undefined
        : {
            Authorization: `Bearer ${userInfo.token}`
          }
    })

    if (result.status === 200) {
      let contentType = result.headers.get("Content-Type")
      if (contentType?.includes("text/plain")) {
        return await result.text()
      } else if (contentType?.includes("application/json")) {
        return await result.json()
      } else {
        return `unknown contenttype: ${contentType}`
      }
    } else if (result.status === 401) {
      console.log("401 Unauthorized, signing out..")
      Methods.logout()
    }
  } catch (e) {
    return e
  }
}

export const Login = async (username: string, password: string, orgId: string) =>
  await PostJson(
    "User/authenticate",
    JSON.stringify(
      orgId === undefined
        ? { username, password }
        : {
            username,
            password,
            orgId
          }
    ),
    { OrgId: orgId },
    true,
    "Login failed"
  )

export const SetPassword = async (resetPassword: string, password: string) =>
  await PostJson("User/SetPassword", JSON.stringify({ resetPassword, password }), undefined, true)

export const GetOwnerOrganizations = async (email: string) =>
  await PostJson("User/GetOwnersOrganizations", JSON.stringify(email), undefined, true)

export const SendResetPasswordEmail = async (email: string, organizationId: number) =>
  await PostJson("User/SendResetPasswordLink", JSON.stringify({ email, organizationId }), undefined, true)

export const ValidateResetPasswordGuid = async (guid: string) =>
  await PostJson("User/ValidateResetPassword", JSON.stringify(guid), undefined, true)

export const GetTranslations = async (orgId?: string, noAuth?: boolean) =>
  await GetRequest("Translation", orgId ? { OrgId: orgId } : undefined, noAuth)

export const GetProfile = async () => await GetRequest("Profile")

export const SaveAccountInfo = async (accountInfo: AccountInfo) =>
  await PostJson("Profile/SaveAccountInfo", JSON.stringify(accountInfo))

export const SavePaymentInfo = async (paymentInfo: PaymentInfo) =>
  await PostJson("Profile/SavePaymentInfo", JSON.stringify(paymentInfo))

export const GetRoomContent = async () => await GetRequest(`MyObjects/GetRoomContent`)

export const NewReservation = async (
  fromDate: Date,
  toDate: Date,
  guestFirstname: string,
  guestLastname: string,
  guestIsOwner: boolean,
  guestRoomId: string,
  numberOfGuests: number
) =>
  await PostJson(
    "Reservation/NewReservation",
    JSON.stringify({
      fromDate,
      toDate,
      guestFirstname,
      guestLastname,
      guestIsOwner,
      guestRoomId,
      numberOfGuests
    })
  )

export const CancelReservation = async (bookingCode: string, guestRoomId: string) =>
  await PostJson("Reservation/CancelReservation", JSON.stringify({ bookingCode, guestRoomId }))

export const NewReservationBlock = async (fromDate: Date, toDate: Date, guestRoomId: string) =>
  await PostJson("Reservation/NewReservationBlock", JSON.stringify({ fromDate, toDate, guestRoomId }))

export const RemoveReservationBlock = async (fromDate: Date, toDate: Date, guestRoomId: string) =>
  await PostJson("Reservation/RemoveReservationBlock", JSON.stringify({ fromDate, toDate, guestRoomId }))

export const GetReservations = async (
  startDate: string,
  endDate: string,
  includeCanceled: boolean,
  basedOnArrival: boolean
) =>
  await GetRequest(
    `Reservation?startDate=${startDate}&endDate=${endDate}&includeCanceled=${includeCanceled}&basedOnArrival=${basedOnArrival}`
  )

export const GetAllFutureReservations = async (
  roomTypeId: string | undefined,
  includeCanceled: boolean
): Promise<OwnerPortalReservation[]> =>
  await GetRequest(
    `Reservation/GetAllFutureReservations?includeCanceled=${includeCanceled}${
      roomTypeId !== undefined ? `&roomTypeId=${roomTypeId}` : ""
    }`
  )

export const GetReservationsAndRestrictions = async (
  startDate: string,
  endDate: string,
  roomTypeId: string,
  includeCanceled: boolean,
  basedOnArrival: boolean
): Promise<
  | ReservationsAndRestrictions
  | {
      error: string
    }
> =>
  await GetRequest(
    `Reservation/GetReservationsAndRestrictions?startDate=${startDate}&endDate=${endDate}&roomTypeId=${roomTypeId}&includeCanceled=${includeCanceled}&basedOnArrival=${basedOnArrival}`
  )

export const GetRestrictions = async (startDate: string, endDate: string, roomTypeId: string) =>
  await GetRequest(`Reservation/GetRestrictions?startDate=${startDate}&endDate=${endDate}&roomTypeId=${roomTypeId}`)

export const GetOwnersObjects = async (): Promise<GuestRoomInfo[]> => await GetRequest("Reservation/GetOwnersObjects")

export const GetOrganizationContactInformation = async (): Promise<OrganizationContactInfo> =>
  await GetRequest(`Contact/GetOrganizationContactInformation`)

export const GetFinanceYearsCalendarObjects = async (): Promise<string[]> =>
  await GetRequest("Finance/GetYearsCalendarObjects")

export const GetFinanceData = async (year: number, roomId: string): Promise<OwnerPortalFinanceData[]> =>
  await GetRequest(`Finance/GetFinanceReport?year=${year}&roomId=${roomId}`)

export const GetImageUrls = async () => await GetRequest(`User/GetImageUrls`)

export const LogError = async (error: ErrorMessage) => await PostJson("Log/LogError", JSON.stringify(error))

export const LogWarning = async (errorMessage: ErrorMessage) =>
  await PostJson("Log/LogWarning", JSON.stringify(errorMessage))

export const AdminLogin = async (username: string, password: string) =>
  await PostJson("Admin/authenticate", JSON.stringify({ username, password }), null, true)

export const AdminAssumeUser = async (ownerId: string) =>
  await PostJson("Admin/assumeuser", JSON.stringify(ownerId), null)

export const AdminGetOwners = async () => await GetRequest(`Admin/GetOwners`)

export const AdminSearchOwners = async (emailSearchString: string) =>
  await GetRequest(`Admin/SearchOwners?emailSearchString=${emailSearchString}`)

export const GetLanguages = async () => await GetRequest(`Profile/GetLanguages`)

export const GetAllRateplans = async (roomTypeId: string, startDate: string, endDate: string) =>
  await GetRequest(`Rate/GetAllRates?roomTypeId=${roomTypeId}&startDate=${startDate}&endDate=${endDate}`)

export const UpdateRatePlanAmount = async (rateUpdate: RateUpdate) =>
  await PostJson(`Rate/UpdateRatePlanAmounts`, JSON.stringify(rateUpdate))

export class Methods {
  public static logout: Function
}
