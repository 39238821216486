import React, { FC, useContext, useMemo, useState } from "react"

// Components
import { Calendar } from "../components/icons"
import { AvailabilityCalendar } from "../components/availability_calendar"
import { BookingDetailsModal } from "../components/bookingdetails_modal"
import NewBookingModal from "../components/newbooking_modal"
import NewBlockModal from "../components/new_block_modal"
import RemoveBlockModal from "../components/remove_block_modal"
import Snackbar from "@material-ui/core/Snackbar"
import { TranslationContext } from "../translationcontext"

// Types
// import { OwnerPortalReservation } from "../generated_models/ownerPortalReservation"
import { AvailabilityProps } from "./interfaces"
import { OwnerPortalReservation } from "../generated_api"

const imageUrl = process.env.NODE_ENV === "development" ? "http://images.local/img/" : "https://images.bookvisit.com/img/"


export const Availability: FC<AvailabilityProps> = ({ selectedObject, onSelectedObjectChanged, ownersObjects }) => {
  const translation = useContext(TranslationContext)

  const [drawerState, setDrawerState] = useState<{
    show: boolean,
    booking: OwnerPortalReservation | undefined
  }>({ show: false, booking: undefined })
  const [newBookingModalState, setNewBookingModalState] = useState<{
    show: boolean,
    dateClicked: Date | undefined,
    isBlock?: boolean,
    roomId?: string,
    nextArrivalDate?: Date,
    nextRestrictionDate?: Date
  }>({ show: false, dateClicked: undefined })
  const [newBlockModalState, setNewBlockModalState] = useState<{
    show: boolean,
    dateClicked: Date | undefined,
    isBlock?: boolean,
    roomId?: string,
    nextArrivalDate?: Date,
    nextRestrictionDate?: Date
  }>({ show: false, dateClicked: undefined })
  const [removeBlockModalState, setRemoveBlockModalState] = useState<{
    show: boolean,
    dateClicked: Date | undefined,
    isBlock?: boolean,
    roomId?: string,
    nextArrivalDate?: Date,
    nextRestrictionDate?: Date
  }>({ show: false, dateClicked: undefined })

  const [snackbarState, setSnackbarState] = useState({ show: false, text: "", color: "seagreen" })
  const [selectedMonth, setSelectedMonth] = useState(`${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, "0")}`)
  const [reloadData, setReloadData] = useState(false)
  const [isCalendarLoading, setIsCalendarLoading] = useState(false)

  const newBookingModalClosing = (saveSuccess?: boolean) => {
    setNewBookingModalState(s => ({ ...s, show: false }))
    if (saveSuccess) {
      setSnackbarState({ show: true, text: translation.common?.saved!, color: "seagreen" })
      setReloadData((x) => !x)
    }
  }

  const newBlockModalClosing = (saveSuccess?: boolean) => {
    setNewBlockModalState(s => ({ ...s, show: false }))
    if (saveSuccess) {
      setSnackbarState({ show: true, text: translation.common?.saved!, color: "seagreen" })
      setReloadData((x) => !x)
    }
  }

  const removeBlockModalClosing = (saveSuccess?: boolean) => {
    setRemoveBlockModalState(s => ({ ...s, show: false }))
    if (saveSuccess) {
      setSnackbarState({ show: true, text: translation?.common?.saved!, color: "seagreen" })
      setReloadData((x) => !x)
    }
  }

  const showSnackbar = React.useCallback((message: string, color?: string) => {
    setSnackbarState({ show: true, color: color ?? "#44923d", text: message })
  }, [])

  const memoizedCalendar = useMemo(() => {
    return <AvailabilityCalendar
      ImageUrl={imageUrl} DataFetchFailed={showSnackbar} IsLoading={isCalendarLoading}
      SetIsLoading={setIsCalendarLoading} ReloadTrigger={reloadData}
      selectedObjectId={selectedObject}
      onMonthChanged={(newValue: string) => new Date(newValue).getFullYear() >= 2020 && setSelectedMonth(newValue)}
      selectedMonth={selectedMonth}
      newBlockClicked={(date: Date, isBlock?: boolean, nextArrivalDate?: Date, nextRestrictionDate?: Date) => setNewBlockModalState({
        show: true,
        dateClicked: date,
        isBlock: isBlock,
        nextArrivalDate: nextArrivalDate,
        nextRestrictionDate: nextRestrictionDate
      })}
      newBookingClicked={(date: Date, isBlock?: boolean, nextArrivalDate?: Date, nextRestrictionDate?: Date) => setNewBookingModalState({
        show: true,
        dateClicked: date,
        isBlock: isBlock,
        nextArrivalDate: nextArrivalDate,
        nextRestrictionDate: nextRestrictionDate
      })}
      removeBlockClicked={(date: Date, isBlock?: boolean, nextArrivalDate?: Date, nextRestrictionDate?: Date) => setRemoveBlockModalState({
        show: true,
        dateClicked: date,
        isBlock: isBlock,
        nextArrivalDate: nextArrivalDate,
        nextRestrictionDate: nextRestrictionDate
      })}
      bookingClicked={(booking: OwnerPortalReservation) => setDrawerState({
        show: true,
        booking
      })} />
  }, [selectedObject, selectedMonth, reloadData, isCalendarLoading, showSnackbar])

  return (
    <>
      <Snackbar className="custom-snack" style={{ ...{ "--bgColor": snackbarState.color } as React.CSSProperties }}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={snackbarState.show}
                autoHideDuration={4000}
                onClose={() => setSnackbarState(x => ({ ...x, show: false }))} message={snackbarState.text}
                key="bottomcenter" />
      <BookingDetailsModal onReservationCancelled={(success: boolean) => {
        if (success) {
          setDrawerState(prev => ({ ...prev, show: false }))
          setSnackbarState({ show: true, color: "#44923d", text: "Reservation was cancelled!" })
          setReloadData((x) => !x)
        } else {
          setSnackbarState({ show: true, color: "#d23a3a", text: "Failed to cancel reservation!" })
        }
      }} onClose={() => setDrawerState((prev) => ({ ...prev, show: false }))} showDrawer={drawerState.show}
                           imageUrl={imageUrl} booking={drawerState.booking} />
      <NewBookingModal selectedObject={ownersObjects.find(x => x.id === selectedObject)}
                       onReservationFailed={() => {
                         setSnackbarState({
                           show: true,
                           color: "#d23a3a",
                           text: translation.common?.reservationFailed!
                         })
                       }} guestRoomId={selectedObject ?? ""} onClose={newBookingModalClosing}
                       show={newBookingModalState.show} nextRestrictionDate={newBookingModalState.nextRestrictionDate}
                       nextArrivalDate={newBookingModalState.nextArrivalDate}
                       selectedDate={newBookingModalState.dateClicked} />
      <NewBlockModal onFail={() => showSnackbar(translation.common?.somethingWentWrong!)}
                     guestRoomId={selectedObject ?? ""} onClose={newBlockModalClosing}
                     show={newBlockModalState.show} selectedDate={newBlockModalState.dateClicked} />
      <RemoveBlockModal selectedObject={ownersObjects.find(x => x.id === selectedObject)}
                        onReservationFailed={() => {
                          setSnackbarState({
                            show: true,
                            color: "#d23a3a",
                            text: translation.common?.reservationFailed!
                          })
                        }} guestRoomId={selectedObject ?? ""} onClose={removeBlockModalClosing}
                        show={removeBlockModalState.show} isBlock={removeBlockModalState.isBlock}
                        nextRestrictionDate={removeBlockModalState.nextRestrictionDate}
                        nextArrivalDate={removeBlockModalState.nextArrivalDate}
                        selectedDate={removeBlockModalState.dateClicked} />
      <div className="bv-main">
        <div className="bv-inner">
          <div className="bv-title-main">
            <div className="bv-icon">
              <Calendar />
            </div>
            <h1>{translation.availability?.availabilityCalendar}</h1>
            {ownersObjects.length > 0 && <div className="bv-actions">
              <div>
                <select disabled={isCalendarLoading} className="custom-select custom-select-lg"
                        value={selectedObject}
                        onChange={(newValue: any) => onSelectedObjectChanged(newValue.target.value)}
                >
                  {ownersObjects.map(o => {
                    return <option value={o.id} key={o.id}>{o.name}</option>
                  })}
                </select>
              </div>
              <div>
                <input disabled={isCalendarLoading} className="custom-select-lg month-picker"
                       onChange={(x: any) => setSelectedMonth(x.target.value)} style={{
                  textTransform: "capitalize", background: "transparent", height: "40px", borderRadius: "3px",
                  border: "1px solid #cfcecc", paddingRight: "0.2rem", width: "100%"
                }} type="month" id="start" name="start" min="2020-01" value={selectedMonth} />
              </div>
            </div>}
          </div>
          {selectedObject !== undefined && memoizedCalendar}
        </div>
      </div>
    </>
  )
}