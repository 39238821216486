import React, { FC, useContext, useEffect, useState } from "react"

// Components
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core"
import { Button as MantineButton, Loader } from "@mantine/core"
import { TranslationContext } from "../translationcontext"
import { useMediaQuery } from "react-responsive"

// API
import { NewReservationBlock } from "../api"

// CSS
import "react-datepicker/dist/react-datepicker.css"

// Types
import { NewBlockModalProps } from "./interfaces"


const NewBlockModal: FC<NewBlockModalProps> = (
  {
    onClose, guestRoomId, show,
    selectedDate, onFail
  }) => {
  const [loading, setLoading] = useState(false)
  const translations = useContext(TranslationContext)
  const [selectedFromDate, setSelectedFromDate] = useState<string | undefined>()
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 500px)" })

  const SaveBlock = async () => {
    let form: any = document.getElementById("newblockform")
    if (!form.reportValidity()) {
      return
    }
    setLoading(true)

    let from = form.fromdate.value
    let to = form.todate.value

    let result = await NewReservationBlock(from, to, guestRoomId)
    if (result === true) {
      onClose(true)
    } else {
      onFail()
    }
    setLoading(false)
  }

  useEffect(() => {
    if (show) {
      setSelectedFromDate(selectedDate?.toLocaleDateString("sv-SE"))
    }
  }, [show, selectedDate])

  return (
    <Dialog open={show} fullScreen={isTabletOrMobile} disableEscapeKeyDown={true}
            onClose={() => onClose()}
            aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{translations.availability?.blockBookings}</DialogTitle>
      <DialogContent style={{ paddingBottom: "20px" }}>
        <DialogContentText id="alert-dialog-description">
          {translations.availability?.newReservationBlockDescription}
        </DialogContentText>
        <form id="newblockform">
          <br />
          <p>{translations.availability?.from}:</p>
          <input required className="month-picker" onChange={(e) => setSelectedFromDate(e.target.value)} style={{
            background: "transparent", height: "40px", borderRadius: "3px",
            border: "1px solid #cfcecc", paddingLeft: "1rem", paddingRight: "1rem", width: "100%"
          }} type="date" id="start" name="fromdate" min={new Date().toLocaleDateString("sv-SE")}
                 defaultValue={selectedDate?.toLocaleDateString("sv-SE")}
          />

          <br /><br />
          <p>{translations.availability?.to}:</p>
          <input required className="month-picker" style={{
            background: "transparent", height: "40px", borderRadius: "3px",
            border: "1px solid #cfcecc", paddingLeft: "1rem", paddingRight: "1rem", width: "100%"
          }} type="date" id="end" name="todate" min={new Date(`'${selectedFromDate}'`).toLocaleDateString("sv-SE")}
                 defaultValue={selectedFromDate ?? ""} />

          <br />
          <br />
        </form>
      </DialogContent>
      <DialogActions style={{ paddingLeft: "24px", paddingRight: "24px", paddingBottom: "15px" }}>
        <MantineButton disabled={loading} onClick={() => onClose()} variant="secondary"
                       style={{ minWidth: "60px" }}>
          {translations.common?.cancel}
        </MantineButton>
        <MantineButton
          onClick={SaveBlock}
          disabled={loading} style={{ minWidth: "80px" }}>
          {loading && <Loader size="sm" role="status" aria-hidden="true"
                              style={{ marginRight: "5px", verticalAlign: "middle" }} />}
          {loading && translations.common?.saving}
          {!loading && translations.common?.save}
        </MantineButton>
      </DialogActions>
    </Dialog>
  )
}

export default NewBlockModal